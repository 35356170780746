import React, { useEffect, useState } from "react";
import Framework from "../components/Framework";
import Gui from "../components/Gui";
import Layout from "../components/layout"

const MainFramework = () => {

  return (
    <Layout>
      <Framework/>
    </Layout>
  );
};

export default MainFramework;
